.ld-ext-right,
.ld-ext-left,
.ld-ext-bottom,
.ld-ext-top,
.ld-over,
.ld-over-inverse,
.ld-over-full,
.ld-over-full-inverse {
  position: relative;
  transition: all 0.3s;
  transition-timing-function: ease-in;
}
.ld-ext-right > .ld,
.ld-ext-left > .ld,
.ld-ext-bottom > .ld,
.ld-ext-top > .ld,
.ld-over > .ld,
.ld-over-inverse > .ld,
.ld-over-full > .ld,
.ld-over-full-inverse > .ld {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em;
  opacity: 0;
  z-index: -100;
  transition: all 0.3s;
  transition-timing-function: ease-in;
}
.ld-ext-right.running > .ld,
.ld-ext-left.running > .ld,
.ld-ext-bottom.running > .ld,
.ld-ext-top.running > .ld,
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
  opacity: 1;
  z-index: auto;
  visibility: visible;
}
.ld-ext-right.running {
  padding-right: 2.5em !important;
}
.ld-ext-right > .ld {
  top: 50%;
  left: auto;
  right: 1em;
}
.ld-ext-left.running {
  padding-left: 2.5em !important;
}
.ld-ext-left > .ld {
  top: 50%;
  right: auto;
  left: 1em;
}
.ld-ext-bottom.running {
  padding-bottom: 2.5em !important;
}
.ld-ext-bottom > .ld {
  top: auto;
  left: 50%;
  bottom: 1em;
}
.ld-ext-top.running {
  padding-top: 2.5em !important;
}
.ld-ext-top > .ld {
  bottom: auto;
  left: 50%;
  top: 1em;
}
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
  z-index: 99999;
}
.ld-over:before,
.ld-over-inverse:before,
.ld-over-full:before,
.ld-over-full-inverse:before {
  content: " ";
  display: block;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  transition-timing-function: ease-in;
  background: rgba(240, 240, 240, 0.8);
}
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld,
.ld-over-full.running:before,
.ld-over-full-inverse.running:before {
  position: fixed;
}
.ld-over-full > .ld {
  color: rgba(0, 0, 0, 0.8);
}
.ld-over-full:before,
.ld-over-full-inverse:before {
  z-index: -1;
  background: rgba(255, 255, 255, 0.8);
}
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
  z-index: 999999;
}
.ld-over.running:before,
.ld-over-inverse.running:before,
.ld-over-full.running:before,
.ld-over-full-inverse.running:before {
  opacity: 1;
  z-index: 999998;
  display: block;
}
.ld-over-inverse > .ld {
  color: rgba(255, 255, 255, 0.8);
}
.ld-over-inverse:before {
  background: rgba(0, 0, 0, 0.6);
}
.ld-over-full-inverse > .ld {
  color: rgba(255, 255, 255, 0.8);
}
.ld-over-full-inverse:before {
  background: rgba(0, 0, 0, 0.6);
}

.ld.reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.ld.xhalf {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.ld.x1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.ld.x2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.ld.x4 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}
.ld.running {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.ld.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.ld-ring,
.ld-spinner {
  width: 1em;
  height: 1em;
  position: relative;
  color: inherit;
  display: inline-block;
}
.ld-ring:after,
.ld-spinner:after {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: inline-block;
  background: center center no-repeat;
  background-size: cover;
}
.ld-ring:after {
  border-radius: 50%;
  border-style: solid;
  border-width: 0.15em;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent;
  box-sizing: border-box;
}
.ld-spinner:after {
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background: none;
  box-shadow: 0 0.5em 0 0 currentColor, 0 -0.5em 0 0 currentColor, 0.5em 0 0 0 currentColor, -0.5em 0 0 0 currentColor,
    0.35355339059327373em 0.35355339059327373em 0 0 currentColor,
    -0.35355339059327373em 0.35355339059327373em 0 0 currentColor,
    0.35355339059327373em -0.35355339059327373em 0 0 currentColor,
    -0.35355339059327373em -0.35355339059327373em 0 0 currentColor;
}
.ld-loader {
  background-size: cover;
}
@keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ld.ld-spin {
  -webkit-animation: ld-spin 1s infinite;
  animation: ld-spin 1s infinite;
}
@keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}
@-webkit-keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}
.ld.ld-spin-fast {
  -webkit-animation: ld-spin-fast 1s infinite;
  animation: ld-spin-fast 1s infinite;
}
