@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
.ld-ext-right,
.ld-ext-left,
.ld-ext-bottom,
.ld-ext-top,
.ld-over,
.ld-over-inverse,
.ld-over-full,
.ld-over-full-inverse {
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
.ld-ext-right > .ld,
.ld-ext-left > .ld,
.ld-ext-bottom > .ld,
.ld-ext-top > .ld,
.ld-over > .ld,
.ld-over-inverse > .ld,
.ld-over-full > .ld,
.ld-over-full-inverse > .ld {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em;
  opacity: 0;
  z-index: -100;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
.ld-ext-right.running > .ld,
.ld-ext-left.running > .ld,
.ld-ext-bottom.running > .ld,
.ld-ext-top.running > .ld,
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
  opacity: 1;
  z-index: auto;
  visibility: visible;
}
.ld-ext-right.running {
  padding-right: 2.5em !important;
}
.ld-ext-right > .ld {
  top: 50%;
  left: auto;
  right: 1em;
}
.ld-ext-left.running {
  padding-left: 2.5em !important;
}
.ld-ext-left > .ld {
  top: 50%;
  right: auto;
  left: 1em;
}
.ld-ext-bottom.running {
  padding-bottom: 2.5em !important;
}
.ld-ext-bottom > .ld {
  top: auto;
  left: 50%;
  bottom: 1em;
}
.ld-ext-top.running {
  padding-top: 2.5em !important;
}
.ld-ext-top > .ld {
  bottom: auto;
  left: 50%;
  top: 1em;
}
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
  z-index: 99999;
}
.ld-over:before,
.ld-over-inverse:before,
.ld-over-full:before,
.ld-over-full-inverse:before {
  content: " ";
  display: block;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
  background: rgba(240, 240, 240, 0.8);
}
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld,
.ld-over-full.running:before,
.ld-over-full-inverse.running:before {
  position: fixed;
}
.ld-over-full > .ld {
  color: rgba(0, 0, 0, 0.8);
}
.ld-over-full:before,
.ld-over-full-inverse:before {
  z-index: -1;
  background: rgba(255, 255, 255, 0.8);
}
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
  z-index: 999999;
}
.ld-over.running:before,
.ld-over-inverse.running:before,
.ld-over-full.running:before,
.ld-over-full-inverse.running:before {
  opacity: 1;
  z-index: 999998;
  display: block;
}
.ld-over-inverse > .ld {
  color: rgba(255, 255, 255, 0.8);
}
.ld-over-inverse:before {
  background: rgba(0, 0, 0, 0.6);
}
.ld-over-full-inverse > .ld {
  color: rgba(255, 255, 255, 0.8);
}
.ld-over-full-inverse:before {
  background: rgba(0, 0, 0, 0.6);
}

.ld.reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.ld.xhalf {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.ld.x1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.ld.x2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.ld.x4 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}
.ld.running {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.ld.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.ld-ring,
.ld-spinner {
  width: 1em;
  height: 1em;
  position: relative;
  color: inherit;
  display: inline-block;
}
.ld-ring:after,
.ld-spinner:after {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: inline-block;
  background: center center no-repeat;
  background-size: cover;
}
.ld-ring:after {
  border-radius: 50%;
  border-style: solid;
  border-width: 0.15em;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent;
  box-sizing: border-box;
}
.ld-spinner:after {
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background: none;
  box-shadow: 0 0.5em 0 0 currentColor, 0 -0.5em 0 0 currentColor, 0.5em 0 0 0 currentColor, -0.5em 0 0 0 currentColor,
    0.35355339059327373em 0.35355339059327373em 0 0 currentColor,
    -0.35355339059327373em 0.35355339059327373em 0 0 currentColor,
    0.35355339059327373em -0.35355339059327373em 0 0 currentColor,
    -0.35355339059327373em -0.35355339059327373em 0 0 currentColor;
}
.ld-loader {
  background-size: cover;
}
@keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ld.ld-spin {
  -webkit-animation: ld-spin 1s infinite;
  animation: ld-spin 1s infinite;
}
@keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}
@-webkit-keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg);
  }
}
.ld.ld-spin-fast {
  -webkit-animation: ld-spin-fast 1s infinite;
  animation: ld-spin-fast 1s infinite;
}

/* MARGINS & PADDINGS */
.p-0 {
  padding: 0 !important;
}
.p-xxs {
  padding: 5px !important;
}
.p-xs {
  padding: 10px !important;
}
.p-sm {
  padding: 15px !important;
}
.p-m {
  padding: 20px !important;
}
.p-md {
  padding: 25px !important;
}
.p-lg {
  padding: 30px !important;
}
.p-xl {
  padding: 40px !important;
}
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m {
  margin: 15px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-t-none {
  margin-top: 0;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-xxl {
  margin-top: 50px;
}
.m-t-xxxl {
  margin-top: 60px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-r-none {
  margin-right: 0;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-b-none {
  margin-bottom: 0;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.space-15 {
  margin: 15px 0;
}
.space-20 {
  margin: 20px 0;
}
.space-25 {
  margin: 25px 0;
}
.space-30 {
  margin: 30px 0;
}
.clickable {
  cursor: pointer;
}
.striped div:nth-child(odd) {
  background-color: #f7f9fa;
}
.sweet-alert button {
  background-color: #34495e;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer;
}
.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.sweet-alert button:hover {
  background-color: #a1d9f2;
}
.sweet-alert button:active {
  background-color: #81ccee;
}
.sweet-alert button.cancel {
  background-color: #d0d0d0;
}
.sweet-alert button.cancel:hover {
  background-color: #c8c8c8;
}
.sweet-alert button.cancel:active {
  background-color: #b6b6b6;
}
.sweet-alert p {
  font-size: 14px;
  font-weight: 400;
}
.sweet-alert h2 {
  font-size: 26px;
}
.sweet-alert {
  padding: 25px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
}
.cg-notify-message.homer-notify {
  background: #fff;
  padding: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  margin-top: 30px;
  color: inherit;
}
.homer-notify.alert-warning {
  border-left: 6px solid #ffb606;
}
.homer-notify.alert-success {
  border-left: 6px solid #62cb31;
}
.homer-notify.alert-danger {
  border-left: 6px solid #c0392b;
}
.homer-notify.alert-info {
  border-left: 6px solid #3498db;
}
.h-modal-icon {
  font-size: 66px;
}
.inmodal .color-line {
  border-radius: 4px 4px 0 0;
}
.modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
}
.modal-backdrop.in {
  opacity: 0.4;
}
.modal-backdrop {
  position: fixed;
  bottom: 0;
}
.modal-dialog {
  margin-top: 80px;
}
.modal-title {
  font-size: 30px;
  font-weight: 300;
}
.modal-header {
  padding: 40px 30px;
  background: #f7f9fa;
}
.modal-body {
  padding: 20px 30px;
}
.modal-header small {
  color: #9d9fa2;
}
.modal-footer {
  background: #f7f9fa;
}
.hmodal-info .color-line {
  background: #3498db;
}
.hmodal-warning .color-line {
  background: #ffb606;
}
.hmodal-success .color-line {
  background: #62cb31;
}
.hmodal-danger .color-line {
  background: #c0392b;
}
.hmodal-info h4.modal-title {
  color: #3498db;
}
.hmodal-warning h4.modal-title {
  color: #ffb606;
}
.hmodal-success h4.modal-title {
  color: #62cb31;
}
.hmodal-danger h4.modal-title {
  color: #c0392b;
}
/* Progress bar */
.progress {
  border-radius: 2px;
  margin-bottom: 10px;
}
.progress-bar {
  background-color: #f1f3f6;
  text-align: right;
  padding-right: 10px;
  color: #6a6c6f;
}
.progress-small,
.progress-small .progress-bar {
  height: 10px;
}
.progress-bar-success {
  border-right: 4px solid #58b62c;
}
.progress-bar-info {
  border-right: 4px solid #258cd1;
}
.progress-bar-warning {
  border-right: 4px solid #eba600;
}
.progress-bar-danger {
  border-right: 4px solid #e43725;
}
.full .progress-bar-success {
  background-color: #62cb31;
  border-right: 4px solid #58b62c;
}
.full .progress-bar-info {
  background-color: #3498db;
  border-right: 4px solid #258cd1;
}
.full .progress-bar-warning {
  background-color: #ffb606;
  border-right: 4px solid #eba600;
}
.full .progress-bar-danger {
  background-color: #e74c3c;
  border-right: 4px solid #e43725;
}
.full .progress-bar {
  color: #ffffff;
}
body.blank {
  background-color: #f1f3f6;
}
.login-container {
  max-width: 420px;
  margin: auto;
  padding-top: 6%;
}
.register-container {
  max-width: 720px;
  margin: auto;
  padding-top: 6%;
}
.lock-container {
  max-width: 420px;
  margin: auto;
  padding-top: 6%;
}
.error-container {
  max-width: 620px;
  margin: auto;
  padding-top: 6%;
}
.big-icon {
  font-size: 120px;
}
.error-container h1 {
  font-size: 44px;
}
.back-link {
  float: left;
  width: 100%;
  margin: 10px;
}
@media (max-width: 420px) {
  .login-container,
  .lock-container {
    margin: auto 10px;
  }
}
@media (max-width: 720px) {
  .register-container {
    margin: auto 10px;
  }
  .lock-container {
    padding-top: 15%;
  }
}
@media (max-width: 620px) {
  .error-container {
    margin: auto 10px;
  }
}
.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 98%;
  margin: 0 auto;
}
.vertical-container::after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}
.v-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
.v-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #e8ebf0;
}
.vertical-timeline-content .btn {
  float: right;
}
.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-block:first-child {
  margin-top: 0;
}
.vertical-timeline-block:last-child {
  margin-bottom: 0;
}
.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid #e4e5e7;
  text-align: center;
  background: #fff;
}
.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}
.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  border: 1px solid #e4e5e7;
}
.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}
.vertical-timeline-content p {
  margin: 1em 0 0 0;
  line-height: 1.6;
}
.vertical-timeline-content .vertical-date {
  font-weight: 500;
  text-align: right;
  font-size: 13px;
}
.vertical-date small {
  color: #62cb31;
  font-weight: 400;
}
.vertical-timeline-content:after,
.vertical-timeline-content:before {
  right: 100%;
  top: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.vertical-timeline-content:after {
  border-color: transparent;
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px;
}
.vertical-timeline-content:before {
  border-color: transparent;
  border-right-color: #e4e5e7;
  border-width: 11px;
  margin-top: -11px;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px;
  }
  .vertical-timeline-content p {
    font-size: 13px;
  }
}
.contact-panel h3 {
  margin-bottom: 2px;
}
.contact-panel h3 a {
  color: inherit;
}
.contact-panel p {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 0;
}
.contact-panel img {
  width: 76px;
  height: 76px;
}
.contact-footer {
  padding: 0 15px;
}
.contact-stat span {
  font-size: 10px;
  font-weight: 500;
  display: block;
  color: #9d9fa2;
  text-transform: uppercase;
}
.contact-stat {
  padding: 8px 0px;
  text-align: center;
}
.contact-panel:hover .panel-body {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: pulse;
  -webkit-animation-name: pulse;
  z-index: 1;
}
/* FULLCALENDAR */
.fc-state-default {
  background-color: #ffffff;
  background-image: none;
  background-repeat: repeat-x;
  box-shadow: none;
  color: inherit;
  text-shadow: none;
}
.fc-state-default {
  border: 1px solid;
}
.fc-button {
  color: inherit;
  border: 1px solid #e4e5e7;
  cursor: pointer;
  display: inline-block;
  height: 1.9em;
  line-height: 1.9em;
  overflow: hidden;
  padding: 0 0.6em;
  position: relative;
  white-space: nowrap;
}
.fc-state-active {
  background-color: #34495e;
  border-color: #34495e;
  color: #ffffff;
}
.fc-header-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}
.fc-content .fc-widget-header,
.fc-content .fc-widget-content {
  border-color: #e4e5e7;
  font-weight: normal;
}
.fc-border-separate tbody {
  background-color: #f8f8f8;
}
.fc-state-highlight {
  background: none repeat scroll 0 0 #f1f3f6;
}
.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px;
}
.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-radius: 2px;
}
.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  padding: 4px 6px;
  background-color: #34495e;
  border-color: #34495e;
}
.fc-event-time,
.fc-event-title {
  color: #717171;
  padding: 0 1px;
}
.ui-calendar .fc-event-time,
.ui-calendar .fc-event-title {
  color: #fff;
}
.fc-view-container .fc-widget-header {
  background: #f7f8fa;
}
.fc-widget-header .fc-day-header {
  padding: 12px 0;
}
.fc-ltr .fc-basic-view .fc-day-number {
  text-align: center;
}
.fc-basic-view td.fc-day-number,
.fc-basic-view td.fc-week-number span {
  padding: 6px;
}
.fc-day-grid-event {
  margin: 2px 6px 0;
}
.note-editor {
  border: 1px solid #e4e5e7;
}
.note-editor .note-toolbar {
  border-bottom: 1px solid #e4e5e7;
}
.note-editor .btn-default {
  border-color: #e4e5e7;
}
.ngHeaderCell {
  border-right: none;
  border-bottom: 1px solid #e4e5e7;
}
.ngCell {
  border-right: none;
}
.ngTopPanel {
  background: #fff;
}
.ngRow.even {
  background: #f9f9f9;
}
.ngRow.selected {
  background: #e5e8ee;
}
.ngRow {
  border-bottom: 1px solid #e4e5e7;
}
.ngCell {
  background-color: transparent;
}
.ngHeaderCell {
  border-right: none;
}
.gridStyle {
  border: 1px solid #e4e5e7;
  height: 400px;
}
.show-grid [class^="col-"] {
  background-color: #f1f3f6 !important;
  border: 1px solid #ddd;
  padding-bottom: 10px;
  padding-top: 10px;
}
.show-grid {
  margin: 15px 0;
}
.dropdown-menu.hdropdown {
  padding: 0;
  z-index: 1060;
}
.dropdown-menu.hdropdown .title {
  padding: 12px 12px;
  text-align: center;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu.hdropdown li {
  padding: 6px 12px;
  text-align: left;
  background: #fafbfc;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu.filedropdown li {
  padding: 6px 6px;
  text-align: left;
  background: #fafbfc;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu.filedropdown li i {
  margin-right: 10px;
}
.dropdown-menu.hdropdown.notification li {
  padding: 12px 12px;
}
.dropdown-menu.hdropdown.notification li .label {
  margin-right: 10px;
}
.dropdown-menu.hdropdown li > a:hover,
.dropdown-menu.filedropdown li > a:hover {
  background: inherit;
}
.dropdown-menu.hdropdown li:hover,
.dropdown-menu.filedropdown li:hover {
  background: #f1f3f6;
}
.dropdown-menu.hdropdown li.summary {
  padding: 6px 12px;
  text-align: center;
  background: #fff;
}
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav .open > a,
.navbar-nav .open > a:hover,
.navbar-nav .open > a:focus {
  color: inherit;
  background-color: #ecedef;
}
.chat-avatar {
  width: 36px;
  height: 36px;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}
.chat-user-name {
  padding: 10px;
}
.chat-user {
  padding: 8px 10px;
  border-bottom: 1px solid #e7eaec;
}
.chat-user a {
  color: inherit;
}
.chat-view {
  z-index: 1070;
}
.chat-users,
.chat-statistic {
  margin-left: -30px;
}
@media (max-width: 992px) {
  .chat-users,
  .chat-statistic {
    margin-left: 0px;
  }
}
.chat-view .ibox-content {
  padding: 0;
}
.chat-message {
  padding: 10px 20px;
}
.message-avatar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-top: 1px;
}
.chat-discussion .chat-message .message-avatar {
  float: left;
  margin-right: 10px;
}
.chat-discussion .message {
  background-color: #f7f8fa;
  text-align: left;
  display: block;
  padding: 10px 20px;
  position: relative;
  border-radius: 4px;
}
.chat-discussion .chat-message .message-date {
  float: right;
}
.chat-discussion .chat-message .message {
  text-align: left;
  margin-left: 55px;
}
.message-date {
  font-size: 10px;
  color: #888888;
}
.message-content {
  display: block;
}
.chat-users,
.chat-discussion {
  overflow-y: auto;
  height: 500px;
}
.chat-discussion .chat-message.left .message-date {
  float: right;
}
.chat-discussion .chat-message.right .message-date {
  float: left;
}
.chat-discussion .chat-message.left .message {
  text-align: left;
  margin-left: 55px;
}
.chat-discussion .chat-message.right .message {
  text-align: right;
  margin-right: 55px;
}
.chat-discussion .chat-message.left .message-avatar {
  float: left;
  margin-right: 10px;
}
.chat-discussion .chat-message.right .message-avatar {
  float: right;
  margin-left: 10px;
}
.chat-discussion {
  padding: 15px 10px;
}
.header-line.extreme-color {
  width: 20%;
}
.header-line {
  height: 6px;
  width: 10%;
  float: left;
  margin: 0;
  position: relative;
}
.header-line div {
  position: absolute;
  top: 70px;
  left: 15px;
  font-weight: 600;
  color: #fff;
}
.header-line div span {
  font-weight: 400;
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.color-demo .header-line {
  height: 118px;
}
.color-demo .header-line:after {
  display: block;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  content: "";
  height: 10px;
  width: 100%;
}
/* Buttons */
.btn {
  border-radius: 3px;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}
.btn-primary.btn-outline {
  color: #34495e;
}
.btn-primary2.btn-outline {
  color: #9b59b6;
}
.btn-success.btn-outline {
  color: #62cb31;
}
.btn-info.btn-outline {
  color: #3498db;
}
.btn-warning.btn-outline {
  color: #ffb606;
}
.btn-warning2.btn-outline {
  color: #e67e22;
}
.btn-danger.btn-outline {
  color: #e74c3c;
}
.btn-danger2.btn-outline {
  color: #c0392b;
}
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}
.btn-primary {
  background-color: #34495e;
  border-color: #34495e;
  color: #ffffff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #3f5872;
  border-color: #3f5872;
  color: #ffffff;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #293a4a;
  border-color: #293a4a;
}
.btn-primary2 {
  background-color: #9b59b6;
  border-color: #9b59b6;
  color: #ffffff;
}
.btn-primary2:hover,
.btn-primary2:focus,
.btn-primary2:active,
.btn-primary2.active,
.open .dropdown-toggle.btn-primary2 {
  background-color: #a86ebf;
  border-color: #a86ebf;
  color: #ffffff;
}
.btn-primary2:active,
.btn-primary2.active,
.open .dropdown-toggle.btn-primary2 {
  background-image: none;
}
.btn-primary2.disabled,
.btn-primary2.disabled:hover,
.btn-primary2.disabled:focus,
.btn-primary2.disabled:active,
.btn-primary2.disabled.active,
.btn-primary2[disabled],
.btn-primary2[disabled]:hover,
.btn-primary2[disabled]:focus,
.btn-primary2[disabled]:active,
.btn-primary2.active[disabled],
fieldset[disabled] .btn-primary2,
fieldset[disabled] .btn-primary2:hover,
fieldset[disabled] .btn-primary2:focus,
fieldset[disabled] .btn-primary2:active,
fieldset[disabled] .btn-primary2.active {
  background-color: #8c49a7;
  border-color: #8c49a7;
}
.btn-success {
  background-color: #62cb31;
  border-color: #62cb31;
  color: #ffffff;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #74d348;
  border-color: #74d348;
  color: #ffffff;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success.active[disabled],
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #56b22b;
  border-color: #56b22b;
}
.btn-info {
  background-color: #3498db;
  border-color: #3498db;
  color: #ffffff;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #4ea5e0;
  border-color: #4ea5e0;
  color: #ffffff;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info.active[disabled],
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #2489cc;
  border-color: #2489cc;
}
.btn-default {
  background-color: #fff;
  border-color: #e4e5e7;
  color: #6a6c6f;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-color: #fff;
  border-color: #d9dbdd;
  color: #606265;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #e4ebee;
  border-color: #e4ebee;
}
.btn-warning {
  background-color: #ffb606;
  border-color: #ffb606;
  color: #ffffff;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #ffbf25;
  border-color: #ffbf25;
  color: #ffffff;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning.active[disabled],
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #e6a300;
  border-color: #e6a300;
}
.btn-warning2 {
  background-color: #e67e22;
  border-color: #e67e22;
  color: #ffffff;
}
.btn-warning2:hover,
.btn-warning2:focus,
.btn-warning2:active,
.btn-warning2.active,
.open .dropdown-toggle.btn-warning2 {
  background-color: #e98e3d;
  border-color: #e98e3d;
  color: #ffffff;
}
.btn-warning2:active,
.btn-warning2.active,
.open .dropdown-toggle.btn-warning2 {
  background-image: none;
}
.btn-warning2.disabled,
.btn-warning2.disabled:hover,
.btn-warning2.disabled:focus,
.btn-warning2.disabled:active,
.btn-warning2.disabled.active,
.btn-warning2[disabled],
.btn-warning2[disabled]:hover,
.btn-warning2[disabled]:focus,
.btn-warning2[disabled]:active,
.btn-warning2.active[disabled],
fieldset[disabled] .btn-warning2,
fieldset[disabled] .btn-warning2:hover,
fieldset[disabled] .btn-warning2:focus,
fieldset[disabled] .btn-warning2:active,
fieldset[disabled] .btn-warning2.active {
  background-color: #d26f18;
  border-color: #d26f18;
}
.btn-danger {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: #ffffff;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #ea6557;
  border-color: #ea6557;
  color: #ffffff;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger.active[disabled],
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #e43321;
  border-color: #e43321;
}
.btn-danger2 {
  background-color: #c0392b;
  border-color: #c0392b;
  color: #ffffff;
}
.btn-danger2:hover,
.btn-danger2:focus,
.btn-danger2:active,
.btn-danger2.active,
.open .dropdown-toggle.btn-danger2 {
  background-color: #d24637;
  border-color: #d24637;
  color: #ffffff;
}
.btn-danger2:active,
.btn-danger2.active,
.open .dropdown-toggle.btn-danger2 {
  background-image: none;
}
.btn-danger2.disabled,
.btn-danger2.disabled:hover,
.btn-danger2.disabled:focus,
.btn-danger2.disabled:active,
.btn-danger2.disabled.active,
.btn-danger2[disabled],
.btn-danger2[disabled]:hover,
.btn-danger2[disabled]:focus,
.btn-danger2[disabled]:active,
.btn-danger2.active[disabled],
fieldset[disabled] .btn-danger2,
fieldset[disabled] .btn-danger2:hover,
fieldset[disabled] .btn-danger2:focus,
fieldset[disabled] .btn-danger2:active,
fieldset[disabled] .btn-danger2.active {
  background-color: #a73225;
  border-color: #a73225;
}
.btn-link {
  padding: 0;
  margin: 0;
  border: none;
  outline: 0 !important;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  color: #6a6c6f;
}
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  background-image: none;
}
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #9d9fa2;
}
.btn-outline {
  color: inherit;
  background-color: transparent;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-xs {
  border-radius: 3px;
  font-size: 11px;
  line-height: 1.5;
  padding: 1px 7px;
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}
.btn-circle.btn-xs {
  width: 20px;
  height: 20px;
  padding: 4px 8px;
  border-radius: 25px;
  font-size: 10px;
  line-height: 1;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}
/* Fonts */
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-bold {
  font-weight: 600;
}
.font-extra-bold {
  font-weight: 700;
}
.font-uppercase {
  text-transform: uppercase;
}
.font-trans {
  opacity: 0.5 !important;
}
.text-big {
  font-size: 60px;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 200;
}
/* Layout */
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden !important;
}
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  font-size: 13px;
  color: #6a6c6f;
  margin: 0;
  padding: 0;
  /*height: 100%;*/
}
#header {
  background-color: #fff;
  display: block;
  min-height: 62px;
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: 1px solid #e4e5e7;
}
#menu {
  width: 180px;
  bottom: 0;
  float: left;
  left: 0;
  position: absolute;
  top: 62px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
/*#wrapper {
  margin: 0 0 0 180px;
  padding: 0;
  background: #f1f3f6;
  border-left: 1px solid #e4e5e7;
  transition: all 0.4s ease 0s;
  position: relative;
  min-height: 100%;
}
.content {
  padding: 25px 40px 40px 40px;
  min-width: 320px;
}
.footer {
  padding: 10px 18px;
  background: #ffffff;
  border-top: 1px solid #e4e5e7;
  transition: all 0.4s ease 0s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 768px) {*/
.content {
  padding: 15px 15px 15px 15px;
  min-width: 320px;
}
/*}
.content-boxed {
  max-width: 1100px;
  margin: auto;
  padding-top: 60px;
}*/
#page-header {
  padding: 20px 40px;
}
.color-line {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top, right top,
    from(#34495e),
    color-stop(25%, #34495e),
    color-stop(25%, #9b59b6),
    color-stop(35%, #9b59b6),
    color-stop(35%, #3498db),
    color-stop(45%, #3498db),
    color-stop(45%, #62cb31),
    color-stop(55%, #62cb31),
    color-stop(55%, #ffb606),
    color-stop(65%, #ffb606),
    color-stop(65%, #e67e22),
    color-stop(75%, #e67e22),
    color-stop(85%, #e74c3c),
    color-stop(85%, #e74c3c),
    color-stop(85%, #c0392b),
    to(#c0392b)
  );
  background-image: linear-gradient(
    to right,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.fixed-navbar #header {
  width: 100%;
  position: fixed;
  z-index: 1000;
}
/*.fixed-navbar #wrapper {
  top: 62px;
}*/
.fixed-sidebar #menu {
  position: fixed;
}
/*.fixed-sidebar.show-sidebar #menu {
  width: 180px;
}
.fixed-footer .footer {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  left: 181px;
  right: 0;
}
.fixed-footer.hide-sidebar .footer {
  left: 0;
}
@media (max-width: 768px) {
  .fixed-footer .footer {
    left: 0;
  }
  .fixed-footer.show-sidebar .footer {
    left: 181px;
  }
}*/
#right-sidebar {
  background-color: #fff;
  border-left: 1px solid #e4e5e7;
  position: fixed;
  top: 0;
  width: 260px !important;
  z-index: 1009;
  bottom: 0;
  right: 0;
  display: none;
  overflow: auto;
}
#right-sidebar.sidebar-open {
  display: block;
}
.fixed-small-header .small-header {
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  margin-left: 181px;
  z-index: 100;
}
.fixed-small-header .content {
  padding-top: 80px;
}
.fixed-small-header.hide-sidebar .small-header {
  margin-left: 0;
}
.fixed-small-header.page-small .small-header {
  margin-left: 0;
}
.fixed-small-header.page-small .content {
  padding-top: 120px;
}
.fixed-small-header.page-small.show-sidebar .small-header {
  margin-left: 181px;
  overflow: hidden;
}
.boxed {
  background-color: #ffffff;
}
.boxed .profile-picture {
  background-color: white;
}
.boxed .boxed-wrapper {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.55);
}
/* Lists */
.list .list-item-container {
  float: left;
}
.list-item-container div:first-child {
  border-top: none;
}
.list .list-item .list-item-content p {
  margin-bottom: 3px;
  margin-top: 0;
}
.list .list-item-date {
  color: #aaaaaa;
  font-size: 10px;
  text-align: center;
}
.list .list-item {
  float: left;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #e4e5e7;
}
.list .list-item-container-small {
  min-width: 80px;
  text-align: center;
}
.list .list-item .list-item-content .list-item-right {
  height: 100%;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.list .list-item .list-item-content h1,
.list .list-item .list-item-content h2,
.list .list-item .list-item-content h3,
.list .list-item .list-item-content h4,
.list .list-item .list-item-content h5,
.list .list-item .list-item-content h6,
.list .list-item .list-item-content .list-item-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}
/* Panels */
.hpanel > .panel-heading {
  color: inherit;
  font-weight: 600;
  padding: 10px 4px;
  /*transition: all .3s;*/
  border: 1px solid transparent;
}
.hpanel .hbuilt.panel-heading {
  border-bottom: none;
}
.hpanel .panel-footer,
.hpanel .panel-section {
  color: inherit;
  border: 1px solid #e4e5e7;
  border-top: none;
  font-size: 90%;
  background: #f7f9fa;
  padding: 10px 15px;
}
.hpanel.panel-collapse > .panel-heading,
.hpanel .hbuilt {
  background: #fff;
  border-color: #e4e5e7;
  border: 1px solid #e4e5e7;
  padding: 10px 10px;
  border-radius: 2px;
}
.hpanel .panel-body {
  background: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 20px;
  position: relative;
}
.hpanel.panel-group .panel-body:first-child {
  border-top: 1px solid #e4e5e7;
}
.hpanel.panel-group .panel-body {
  border-top: none;
}
/*.panel-collapse .panel-body {
  border: none;
}*/
.hpanel {
  background: none;
  border: none;
  box-shadow: none;
  margin-bottom: 25px;
}
.panel-tools {
  display: inline-block;
  float: right;
  margin-top: 0;
  padding: 0;
  position: relative;
}
.hpanel .alert {
  margin-bottom: 0;
  border-radius: 0;
  border: 1px solid #e4e5e7;
  border-bottom: none;
}
.panel-tools a {
  margin-left: 5px;
  color: #9d9fa2;
  cursor: pointer;
}
.hpanel.hgreen .panel-body {
  border-top: 2px solid #62cb31;
}
.hpanel.hblue .panel-body {
  border-top: 2px solid #3498db;
}
.hpanel.hyellow .panel-body {
  border-top: 2px solid #ffb606;
}
.hpanel.hviolet .panel-body {
  border-top: 2px solid #9b59b6;
}
.hpanel.horange .panel-body {
  border-top: 2px solid #e67e22;
}
.hpanel.hred .panel-body {
  border-top: 2px solid #e74c3c;
}
.hpanel.hreddeep .panel-body {
  border-top: 2px solid #c0392b;
}
.hpanel.hnavyblue .panel-body {
  border-top: 2px solid #34495e;
}
.hpanel.hbggreen .panel-body {
  background: #62cb31;
  color: #fff;
  border: none;
}
.hpanel.hbgblue .panel-body {
  background: #3498db;
  color: #fff;
  border: none;
}
.hpanel.hbgyellow .panel-body {
  background: #ffb606;
  color: #fff;
  border: none;
}
.hpanel.hbgviolet .panel-body {
  background: #9b59b6;
  color: #fff;
  border: none;
}
.hpanel.hbgorange .panel-body {
  background: #e67e22;
  color: #fff;
  border: none;
}
.hpanel.hbgred .panel-body {
  background: #e74c3c;
  color: #fff;
  border: none;
}
.hpanel.hbgreddeep .panel-body {
  background: #c0392b;
  color: #fff;
  border: none;
}
.hpanel.hbgnavyblue .panel-body {
  background: #34495e;
  color: #fff;
  border: none;
}
.panel-group .panel-heading {
  background-color: #f7f9fa;
}
.panel-body {
  min-width: 480px;
}
.small-header .hpanel {
  margin-bottom: 0;
}
.small-header {
  padding: 0 !important;
}
.small-header .panel-body {
  padding: 15px 25px;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0;
}
.panel-body h5,
.panel-body h4 {
  font-weight: 600;
}
.small-header .panel-body h2 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0 0;
}
.small-header .panel-body small {
  color: #838589;
}
.hbreadcrumb {
  padding: 2px 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #fff;
  font-size: 11px;
}
.hbreadcrumb > li {
  display: inline-block;
}
.hbreadcrumb > li + li:before {
  padding: 0 5px;
  color: #34495e;
}
.hbreadcrumb > .active {
  color: #9d9fa2;
}
.wrapper {
  padding: 10px 20px;
}
.hpanel.collapsed .panel-body,
.hpanel.collapsed .panel-footer {
  display: none;
}
.hpanel.collapsed .fa.fa-chevron-up:before {
  content: "\F078";
}
.hpanel.collapsed .fa.fa-chevron-down:before {
  content: "\F077";
}
.hpanel.collapsed.panel-collapse .panel-body {
  border-width: 0 1px 1px 1px;
  border-color: #e4e5e7;
  border-style: solid;
}
.hpanel.collapsed .hbuilt.panel-heading {
  border-bottom: 1px solid #e4e5e7;
}
body.fullscreen-panel-mode {
  overflow-y: hidden;
}
.hpanel.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}
.hpanel.fullscreen .showhide {
  display: none;
}
.hpanel.fullscreen .panel-body {
  min-height: calc(100% - 77px);
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover,
.nav-tabs > li > a.active:focus {
  border: 1px solid #e4e5e7;
  border-bottom-color: transparent;
}
.nav-tabs {
  border-bottom: 1px solid #e4e5e7;
}
.tab-pane > .panel-body {
  border-top: none;
}
.panel-body {
  height: 100%;
}
.tabs-left .tab-pane .panel-body,
.tabs-right .tab-pane .panel-body {
  border-top: 1px solid #e4e5e7;
}
.nav-tabs > li a:hover {
  background: transparent;
  border-color: transparent;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tabs-left .panel-body {
  position: static;
}
.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs {
  width: 20%;
}
.tabs-left .panel-body {
  width: 80%;
  margin-left: 20%;
}
.tabs-right .panel-body {
  width: 80%;
  margin-right: 20%;
}
/*.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}*/
.tabs-below > .nav-tabs {
  border-top: 1px solid #e4e5e7;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #e4e5e7;
  border-bottom-color: transparent;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus,
.tabs-left > li > a.active,
.tabs-left > li > a.active:hover,
.tabs-left > li > a.active:focus {
  border-color: #e4e5e7 transparent #e4e5e7 #e4e5e7;
  *border-right-color: #ffffff;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #e4e5e7 #e4e5e7 #e4e5e7 transparent;
  *border-left-color: #ffffff;
  z-index: 1;
}
.tooltip-inner {
  background-color: #34495e;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #34495e;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #34495e;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #34495e;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #34495e;
}
/* Opacity */
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}
/* Google Maps */
.google-map {
  height: 300px;
}
.hr-line-dashed {
  background-color: transparent;
  border-top: 1px dotted #f1f3f6;
  color: transparent;
  height: 1px;
  margin: 20px 0;
}
/* Mixin */
.animated-panel {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* Color */
.h-bg-navy-blue {
  background: #34495e;
}
.h-bg-violet {
  background: #9b59b6;
}
.h-bg-blue {
  background: #3498db;
}
.h-bg-green {
  background: #62cb31;
}
.h-bg-yellow {
  background: #ffb606;
}
.h-bg-orange {
  background: #e67e22;
}
.h-bg-red {
  background: #e74c3c;
}
.h-bg-red-deep {
  background: #c0392b;
}
.text-primary {
  color: #34495e;
}
.text-primary-2 {
  color: #9b59b6;
}
.text-info {
  color: #3498db;
}
.text-success {
  color: #62cb31;
}
.text-warning {
  color: #ffb606;
}
.text-danger {
  color: #e74c3c;
}
.text-white {
  color: #fff;
}
.border-top {
  border-top: 1px solid #e4e5e7;
}
.border-bottom {
  border-bottom: 1px solid #e4e5e7;
}
.border-right {
  border-right: 1px solid #e4e5e7;
}
.border-left {
  border-left: 1px solid #e4e5e7;
}
.jqstooltip {
  box-sizing: content-box;
}
/* Inputs */
.has-warning .form-control,
.has-error .form-control,
.has-success .form-control {
  box-shadow: none;
}
.has-error .form-control {
  border-color: #d62c1a !important;
}
.has-warning .form-control {
  border-color: #d29400 !important;
}
.has-success .form-control {
  border-color: #4ea227 !important;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #d62c1a;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #d29400;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #4ea227;
}
/* Backgrounds */
.bg-white {
  background-color: #fff;
}
.bg-light-soft {
  background-color: #f1f3f6;
}
.bg-light {
  background-color: #f7f9fa;
}
/* Slider */
#logo {
  float: left;
  width: 180px;
  background-color: #34495e;
  padding: 18px 10px 18px 18px;
  height: 56px;
  text-align: center;
}
#logo span {
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}
#logo.light-version {
  background-color: #f7f9fa;
  border-bottom: 1px solid #e4e5e7;
  text-align: center;
}
#logo.light-version span {
  font-weight: 600;
  color: #34495e;
  font-size: 14px;
}
.profile-picture {
  padding: 20px 20px;
  text-align: center;
}
.stats-box div {
  display: inline-block;
  vertical-align: middle;
}
.stats-label {
  line-height: 1.2;
}
.stats-label small {
  font-size: 80%;
  line-height: 0.6;
}
.stats-box-value {
  background: #ffffff;
}
.stats-box-detail {
  background: #34495e;
  color: #fff;
  position: relative;
}
/* Labels */
.label-menu-corner .label {
  font-size: 10px;
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 18px;
  top: 12px;
}
/* Menu */
#side-menu {
  background: #f7f9fa;
}
#side-menu li a {
  color: #6a6c6f;
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 20px;
}
#side-menu li {
  border-bottom: 1px solid #e4e5e7;
}
#side-menu li:first-child {
  border-top: 1px solid #e4e5e7;
}
#side-menu li.active a,
#side-menu li:hover a {
  color: #34495e;
}
#side-menu li .nav-second-level li a {
  padding: 10px 10px 10px 30px;
  color: #6a6c6f;
  text-transform: none;
  font-weight: 600;
}
#side-menu li .nav-second-level li.active a,
#side-menu li .nav-second-level li > a.active {
  color: #34495e;
  background-color: #f7f9fa;
}
#side-menu.nav > li > a:hover,
#side-menu.nav > li > a:focus {
  background: #fff;
}
#side-menu li.active,
#side-menu li > a.active {
  background: #fff;
}
.arrow {
  float: right;
}
.fa.arrow:before {
  content: "\F104";
}
.active > a > .fa.arrow:before {
  content: "\F107";
}
#top-navigation {
  height: 56px;
  position: relative;
  float: left;
}
.navbar-static-top {
  margin: 0 0 0 180px;
}
.navbar.navbar-static-top a,
.nav.navbar-nav li a {
  color: #9d9fa2;
}
.header-link {
  padding: 18px 26px 17px 26px;
  font-size: 14px;
  float: left;
  border-right: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  margin-right: 15px;
  background: #f7f9fa;
  cursor: pointer;
}
.header-link:hover {
  background: #f1f4f6;
}
.navbar-nav > li,
.navbar-nav > li > a {
  height: 56px;
}
.navbar-nav > li > a {
  padding: 15px 20px;
  font-size: 25px;
}
.navbar-nav > li:last-child > a {
  padding-right: 35px;
}
.navbar.navbar-static-top .hide-menu {
  color: #9d9fa2;
}
.panel-title {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
/* Box */
.box {
  padding: 40px;
}
.box-rb-border {
  border-right: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.box-b-border {
  border-bottom: 1px solid #e4e5e7;
}
.box-r-border {
  border-right: 1px solid #e4e5e7;
}
/* Height */
.h-100 {
  min-height: 100px;
}
.h-150 {
  min-height: 150px;
}
.h-200 {
  min-height: 200px;
}
.h-300 {
  min-height: 300px;
}
.h-400 {
  min-height: 400px;
}
.h-500 {
  min-height: 500px;
}
.h-600 {
  min-height: 600px;
}
/* Sidebar Hide */
/*body.hide-sidebar #menu {
  margin-left: -180px;
}
body.hide-sidebar #wrapper {
  margin-left: 0;
}
body.page-small #menu {
  margin-left: -180px;
}
body.page-small #wrapper {
  margin-left: 0;
}
body.page-small.show-sidebar #menu {
  margin-left: 0;
}
body.page-small.show-sidebar #wrapper {
  margin-left: 180px;
}*/
/*a {
  cursor: pointer;
  color: #34495e;
}
a:hover,
a:focus {
  color: #34495e;
  text-decoration: none;
}*/
.no-padding {
  padding: 0 !important;
}
.no-borders {
  border: none !important;
}
.no-margins {
  margin: 0 !important;
}
.no-background {
  background: transparent !important;
}
.navbar-form-custom {
  float: left;
  height: 52px;
  padding: 0;
  width: 180px;
}
.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 13px;
  height: 52px;
  margin: 0;
  z-index: 1060;
  padding: 0;
}
.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none;
}
.vertical-mid {
  display: table;
}
.vertical-mid * {
  display: table-cell;
  vertical-align: middle;
}
/* Labels */
.label-success {
  background-color: #62cb31;
}
/* LIST GROUP */
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: #34495e;
  border-color: #34495e;
  color: #ffffff !important;
}
.list-group-item-heading {
  margin-top: 10px;
}
.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  line-height: inherit;
}
.list-group-item {
  background-color: inherit;
  border: 1px solid #e4e5e7;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}
.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}
.no-padding .list-group {
  margin-bottom: 0;
}
.well {
  background: #f7f9fa;
  /*box-shadow: none;*/
}
/* Stats */
.stats h4,
h5,
h6 {
  margin: 5px 0;
}
.stats-title h4 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
/* Widths */
.w-xxs {
  min-width: 60px;
}
.w-xs {
  min-width: 100px;
}
.w-sm {
  min-width: 120px;
}
.w-m {
  min-width: 140px;
}
.w-lg {
  min-width: 160px;
}
.w-xl {
  min-width: 180px;
}
.w-xxl {
  min-width: 200px;
}
.w-100 {
  width: 100%;
}
.flot-chart {
  display: block;
  height: 300px;
  position: relative;
}
.flot-chart-content {
  width: 100%;
  height: 100%;
}
.stats .flot-chart {
  height: 60px;
}
.feed-activity-list .feed-element {
  border-bottom: 1px solid #e7eaec;
}
.feed-element:first-child {
  margin-top: 0;
}
.feed-element {
  padding-bottom: 15px;
}
.feed-element,
.feed-element .media {
  margin-top: 15px;
}
.feed-element,
.media-body {
  overflow: hidden;
}
.feed-element > .pull-left {
  margin-right: 10px;
}
.feed-element img.img-circle,
.dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}
.feed-element .well {
  border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}
.feed-element .actions {
  margin-top: 10px;
}
.feed-element .photos {
  margin: 10px 0;
}
.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}
.feed-element img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android
  filter: gray;
  /* IE6-9
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}
.small-logo {
  display: none;
  text-align: center;
  padding-top: 18px;
  font-size: 14px;
  font-weight: 800;
}
.img-small {
  width: 62px;
  height: 62px;
}
@media (max-width: 768px) {
  .navbar-form-custom {
    display: none;
  }
  .navbar-static-top {
    margin: 0;
  }
  .small-logo {
    display: block;
    float: left;
  }
  .header-line div {
    display: none;
  }
  .color-demo .header-line {
    height: 48px;
  }
  #hbreadcrumb {
    float: none !important;
    margin-bottom: 10px;
    margin-top: 0;
  }
}
/*@media (max-width: 320px) {
  #wrapper {
    overflow: hidden;
  }
}*/
.canvass {
  -webkit-transition: width 0.01s, height 0.01s;
  transition: width 0.01s, height 0.01s;
}
.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  padding: 10px 10px;
  vertical-align: middle;
}
.icons-box {
  padding: 20px;
}
.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.icons-box .infont {
  text-align: center;
  margin-bottom: 25px;
}
.icons-box .infont i {
  font-size: 35px;
  display: block;
  color: #676a6c;
}
.icons-box .infont span {
  color: #a6a8a9;
}
.icons-box .infont span {
  margin: 1px;
  display: block;
}
.fullpanel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1070;
  overflow-x: scroll;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.transition {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.normalheader .hpanel {
  margin-bottom: 0;
}
.normalheader {
  min-width: 320px;
  padding: 40px 40px 0px 40px;
}
@media (max-width: 768px) {
  .normalheader {
    padding: 15px 15px 0px 15px;
  }
}
.normalheader h2 {
  margin-top: 0px;
}
.input-xs {
  height: 20px;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 500;
}
.clip-header i {
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.clip-header {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 8px;
  background: #f7f9fa;
  padding: 4px 5px;
  border-left: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.dropdown-menu {
  font-size: 13px;
  border-radius: 3px;
}
.dropdown-example {
  display: inline-block;
  margin: 0px 10px 10px 10px;
}
.dropdown-example .dropdown-menu {
  position: static;
  display: block;
}
.pagination-example .pagination {
  margin: 0 0 10px 0;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #3498db;
  border-color: #258cd1;
  color: #fff;
  cursor: default;
  z-index: 2;
}
.hbreadcrumb a.active {
  color: #9d9fa2;
}
.form-control {
  border-color: #e4e5e7 !important;
}
.input-group-addon {
  background: #fafbfc;
  border-color: #e4e5e7 !important;
}
.sidebar-button {
  padding: 4px 10px 0 10px;
}
.sidebar-button i {
  font-size: 20px;
}
/* Splash style */
.splash {
  position: fixed;
  z-index: 2000;
  background: white;
  color: gray;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.splash-title {
  text-align: center;
  max-width: 500px;
  margin: 15% auto;
  padding: 20px;
}
.splash-title h1 {
  font-size: 26px;
}
/* Checkbox styles */
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #34495e;
  border-color: #34495e;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #e74c3c;
  border-color: #e74c3c;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #3498db;
  border-color: #3498db;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffb606;
  border-color: #ffb606;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #62cb31;
  border-color: #62cb31;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #34495e;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #34495e;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #34495e;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #e74c3c;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #e74c3c;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #e74c3c;
}
.radio-info input[type="radio"] + label::after {
  background-color: #3498db;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #3498db;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #3498db;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #ffb606;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffb606;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffb606;
}
.radio-success input[type="radio"] + label::after {
  background-color: #62cb31;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #62cb31;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #62cb31;
}
.checkbox input:hover,
.radio input:hover {
  cursor: pointer;
}
/* UI Sortable */
.ui-sortable .panel-body {
  cursor: move;
}
.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: #f7f9fa;
}
.ibox.ui-sortable-placeholder {
  margin: 0px 0px 23px !important;
}
/* Badge colors */
.badge.badge-success {
  background-color: #62cb31;
}
.badge.badge-info {
  background-color: #3498db;
}
.badge.badge-danger {
  background-color: #e74c3c;
}
.badge.badge-warning {
  background-color: #ffb606;
}
.badge.badge-primary {
  background-color: #34495e;
}
/* Tables */
.no-padding table {
  margin-bottom: 0;
}
/* Validation */
.form-control.error {
  border-color: #e74c3c !important;
}
label.error,
label span.error {
  color: #e74c3c;
  margin: 5px 0 0 0;
  font-weight: 400;
}
label span.error {
  margin-left: 5px;
}
label.error:before {
  content: "\F071";
  font-family: "FontAwesome";
  margin: 0 4px;
}
/* File manager */
.hpanel .panel-body.file-body {
  padding: 30px 0;
  text-align: center;
}
.file-body i.fa {
  font-size: 56px;
}
/* Filter search */
.filter-item {
  margin-bottom: 0;
}
.filter-item .panel-body {
  padding: 10px 20px;
  border-bottom: none;
}
.filter-item a {
  color: #6a6c6f;
}
.filter-item:last-child {
  border-bottom: 1px solid #e4e5e7;
}
.filter-item:hover .panel-body,
.filter-item.active .panel-body {
  background: #fafcfd;
  z-index: 100;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.175);
}
.filter-item::-moz-selection .panel-body {
  background: #fafcfd;
}
.filter-item::selection .panel-body {
  background: #fafcfd;
}
.filter-item h4 {
  margin-bottom: 5px;
}
/* Big menu */
.hdropdown.bigmenu {
  padding: 30px 30px 0px 30px;
}
.bigmenu a i {
  font-size: 46px;
}
.bigmenu a {
  display: block;
  text-align: center;
  margin: 0 10px 30px 0;
  min-width: 100px;
}
.bigmenu h5 {
  font-size: 13px;
  margin-top: 10px;
}
/* Notes page */
@media (min-width: 991px) {
  #notes {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}
#notes .panel-body:first-child {
  border-top: none;
}
.note-content textarea {
  border: none;
  width: 100%;
  padding: 0;
  min-height: 360px;
}
.note-link h5 {
  margin-top: 0;
}
.note-link:hover {
  background: #f7f9fa;
}
/* App Plans */
.plan-box {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  opacity: 0.7;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
.plan-box.active,
.plan-box:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
/* Mobile navbar */
@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
  }
  .mobile-menu .navbar-nav > li,
  .mobile-menu .navbar-nav {
    float: none;
  }
}
.mobile-navbar {
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 100;
}
.mobile-navbar .navbar-nav {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e4e5e7;
}
.mobile-navbar .navbar-nav > li > a {
  padding: 15px 15px;
  font-size: 14px;
  color: #6a6c6f;
}
.mobile-navbar .navbar-nav > li > a:hover,
.mobile-navbar .navbar-nav > li > a:focus,
.mobile-navbar .navbar-nav .open > a,
.mobile-navbar .navbar-nav .open > a:hover,
.mobile-navbar .navbar-nav .open > a:focus {
  border-bottom: none;
}
@media (min-width: 769px) {
  .mobile-navbar,
  .mobile-navbar.collapse.in {
    display: none;
  }
}
/* CodeMirror */
.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}
.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
/* Fix for xeditable and chekcbox plugin in angularJS version */
.x-edit-checkbox .checkbox label::before {
  display: none;
}
.projects .project-label {
  font-weight: 600;
}
.projects .hpanel .panel-body {
  border-bottom: none;
}
.projects .hpanel:last-child .panel-body {
  border-bottom: 1px solid #e4e5e7;
}
.projects .hpanel:last-child .panel-body {
  border-bottom: 1px solid #e4e5e7;
}
.projects .hpanel h4 a {
  color: #6a6c6f;
}
.projects .project-people img {
  width: 28px;
  height: 28px;
  margin-left: 2px;
  margin-bottom: 4px;
}
.projects .project-info {
  text-align: right;
}
@media (max-width: 768px) {
  .projects .project-info {
    text-align: left;
  }
}
.social-board .social-profile img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}
.social-board .social-profile h5 {
  margin-top: 5px;
  margin-bottom: 0px;
}
.social-board .social-form {
  margin: 10px 0;
}
.social-board .social-form input {
  font-size: 12px;
  padding: 2px 12px;
  height: 28px;
}
.social-talk .social-profile img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.social-talk .text-muted {
  color: #aaacae;
}
.social-talk {
  margin-bottom: 10px;
}
.social-talk .social-content {
  margin-top: 2px;
}
.angular-ui-tree-handle {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
  font-weight: normal;
}
.angular-ui-tree-handle:hover {
  font-weight: bold;
  cursor: pointer;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #e4e5e7;
  box-sizing: border-box;
}
.group-title {
  background-color: #687074 !important;
  color: #fff !important;
}
.tree-node {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
  border-radius: 3px;
}
.tree-node .btn {
  min-width: 22px;
  margin-right: 4px;
}
.tree-node-content {
  margin: 5px 5px 5px 0;
}
.tree-handle {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #e4e5e7;
  box-sizing: border-box;
}
.tour-backdrop {
  opacity: 0.5;
}
.blog-box p {
  font-size: 12px;
}
.blog-box h4 {
  font-weight: bold;
  margin-top: 0;
}
.blog-box .panel-heading {
  background: #fff;
  border-top: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 10px 20px;
  position: relative;
  font-weight: 500;
}
.blog-box .panel-footer {
  color: #909295;
}
.blog-box .panel-heading .media-body {
  line-height: 16px;
}
.blog-box .panel-heading .media img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.blog-box .panel-heading .media-body h5 {
  margin-top: 0;
}
.blog-box .panel-image {
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  position: relative;
}
.blog-box .panel-image .title {
  position: absolute;
  bottom: 10px;
  padding: 0 20px;
  color: #fff;
}
.blog-box .panel-image .title h4 {
  margin-bottom: 0px;
}
.blog-box .panel-image .title a {
  color: #fff !important;
}
.blog-article-box .panel-heading {
  background: #fff;
  border-top: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 80px 10px;
  position: relative;
  font-weight: 500;
  text-align: center;
}
.blog-article-box .panel-heading h4 {
  font-size: 32px;
  font-weight: 700;
  color: #515355;
}
.blog-article-box .panel-body {
  padding: 50px 70px;
}
.blog-article-box .panel-body p {
  font-family: "Times New Roman", Georgia, Serif;
  font-size: 20px;
  line-height: 32px;
  color: #515355;
}
@media (max-width: 768px) {
  .blog-article-box .panel-body {
    padding: 10px 20px;
  }
  .blog-article-box .panel-body p {
    font-family: "Times New Roman", Georgia, Serif;
    font-size: 16px;
    line-height: 28px;
  }
  .blog-article-box .panel-heading {
    padding: 20px 20px;
  }
}
.forum-box .panel-body {
  border-bottom: none;
}
.forum-box .panel-body:last-child {
  border-bottom: 1px solid #e4e5e7;
}
.forum-box .forum-heading h4 {
  margin-bottom: 3px;
  font-weight: 600;
  margin-top: 5px;
  display: inline;
}
.forum-box .forum-heading .label {
  margin-right: 5px;
}
.forum-box .forum-heading .desc {
  color: #909295;
  font-size: 12px;
}
.forum-box .panel-heading {
  border: 1px solid #e4e5e7;
  border-bottom: none;
  font-size: 90%;
  background: #f7f9fa;
  padding: 10px 20px;
}
.forum-box .forum-info {
  text-align: right;
  line-height: 20px;
}
.forum-box .forum-info .number {
  display: block;
  font-weight: 600;
  font-size: 22px;
  margin-top: 5px;
}
.forum-box .forum-info span {
  font-size: 12px;
}
.forum-box .panel-body .media-image {
  text-align: center;
  margin-right: 10px;
  min-width: 100px;
}
.forum-box .panel-body .media img {
  margin-top: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.forum-box .author-info {
  font-size: 12px;
}
.forum-box .forum-comments {
  border: 1px solid #e4e5e7;
  border-left: none;
  border-right: none;
  background: #f7f9fa;
  margin-top: 20px;
  padding: 10px 15px;
}
.forum-box .forum-comments .media img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 0;
  margin-bottom: 10px;
}
.forum-box .forum-comments form {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .forum-box .forum-info {
    text-align: left;
  }
}
ul.mailbox-list,
ul.h-list {
  list-style: none;
  padding: 0;
}
ul.mailbox-list li a,
ul.h-list li a {
  padding: 10px 15px;
  display: block;
}
ul.mailbox-list li a i,
ul.h-list li a i {
  margin-right: 6px;
  display: inline-block;
}
ul.mailbox-list li.active a,
ul.h-list li.active a {
  background: #f7f9fa;
  border: 1px solid #e4e5e7;
  font-weight: 600;
  border-radius: 1px;
}
ul.mailbox-list li:hover a,
ul.h-list li:hover a {
  font-weight: 600;
}
.table-mailbox .radio,
.table-mailbox .checkbox {
  margin: 0;
}
.table-mailbox .radio label,
.table-mailbox .checkbox label {
  cursor: default;
}
.table-mailbox tr.unread td a {
  font-weight: 600;
}
.table-mailbox tr td span.label {
  margin-left: 10px;
}
.table-mailbox tr:first-child td {
  border-top: none;
}
.table-mailbox tr td:first-child {
  width: 33px;
  padding-right: 0;
}
.text-center {
  text-align: center;
}
.mailbox-pagination {
  text-align: right;
}
@media (max-width: 768px) {
  .mailbox-pagination {
    text-align: left;
  }
}
.email-compose .note-editor {
  border: none;
}
.email-compose .note-editor .note-editable {
  padding: 15px 25px;
}
.email-compose .note-toolbar {
  padding: 5px 15px;
}
.note-popover .popover .popover-content > .btn-group,
.note-toolbar > .btn-group {
  margin-bottom: 5px;
}
.note-editor .note-toolbar {
  /* Background from footer panel */
  background-color: #f7f9fa;
}
.spinner {
  margin: 20px auto;
  width: 98%;
  height: 50px;
  text-align: center;
  font-size: 15px;
}
.spinner > div {
  background-color: #019999;
  height: 6px;
  width: 100%;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.snack-bar {
  visibility: hidden;
  position: fixed;
  border: 1px solid #e4e5e7;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  min-width: 250px;
  margin-left: -125px;
  left: 50%;
  bottom: 30px;
  z-index: 1000;
}
.snack-bar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.bg-colour-success {
  background-color: #d3ffff;
}
.bg-colour-warning {
  background-color: #f0daa8;
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
/* Flex Utilities */
.d-flex {
  display: flex;
  display: -webkit-flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1-auto {
  flex: 1 1 auto;
}
.flex-0-auto {
  flex: 0 0 auto;
}
.flex-1-0-auto {
  flex: 1 0 auto;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-f-start {
  align-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-around {
  justify-content: space-around;
}

/*Color Codes:
    Dark blue:
        CMYK: 95, 62, 48, 33
        RGB: 0, 72, 89
        HEX: #004859
    Middle blue:
        CMYK: 81, 20, 42, 1
        RGB: 1, 153, 153
        HEX: #019999
    Light blue:
        CMYK: 68, 0, 33, 0
        RGB: 0, 204, 194
        HEX: #00ccc2
*/
/*
    Begin navbar styling
*/
/* Override react-dates styles */
.SingleDatePickerInput {
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
}
.SingleDatePickerInput .SingleDatePickerInput_calendarIcon {
  padding: 8px;
  vertical-align: baseline;
  vertical-align: initial;
  margin: 0;
}
.SingleDatePickerInput .DateInput_input {
  padding: 8px 0;
  font-family: inherit !important;
  font-size: inherit !important;
}
/*#region Navbar */
#header {
  background-color: #f7f9fa;
  min-width: 320px;
}

#header * {
  color: #004859;
}

button.header-link {
  margin-right: 0;
  border-width: 0;
  border-right-width: 1px;
}

#header .navbar-brand {
  height: 55px;
  padding-left: 8px;
}

#header .navbar-brand img {
  height: 100%;
}

#header .navbar {
  margin-bottom: 0;
  border: none;
}

#header .navbar-collapse {
  border-top: 1px solid #e4e5e7;
}

.color-line {
  background-color: #004859;
  background-image: -webkit-linear-gradient(left, #004859, #019999);
  background-image: -webkit-linear-gradient(left, #004859, #019999);
  background-image: -webkit-gradient(linear, left top, right top, from(#004859), color-stop(33%, #004859), color-stop(33%, #019999), color-stop(67%, #019999), color-stop(67%, #00ccc2), to(#00ccc2));
  background-image: -webkit-linear-gradient(left, #004859, #004859 33%, #019999 33%, #019999 67%, #00ccc2 67%, #00ccc2);
  background-image: linear-gradient(to right, #004859, #004859 33%, #019999 33%, #019999 67%, #00ccc2 67%, #00ccc2);
  background-size: 100% 6px;
  background-position: 0 100%;
  background-repeat: repeat-x;
}

#logo.light-version {
  padding: 0;
  border-right: 1px solid;
  border-color: inherit;
}

#logo a {
  padding: 0 15px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

#logo img {
  width: 100%;
}

.navbar-nav > li > a {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.navbar-nav > li > a > i {
  font-size: 25px;
  margin-right: 10px;
}

.navbar-nav li.dropdown {
  height: auto;
}

.navbar-nav .dropdown-menu {
  width: 100%;
  padding-left: 35px;
  font-size: 15px;
}

@media (min-width: 768px) {
  .color-line {
    background-size: 50% 6px;
  }

  #header .navbar-header {
    border-right: 1px solid #e4e5e7;
    width: 180px;
  }

  #header .navbar-brand {
    padding-left: 15px;
  }

  #header .navbar-collapse {
    border: none;
    background-color: #fff;
    margin-left: 180px;
  }

  .navbar-nav .dropdown-menu {
    padding-left: 27px;
  }
}
/*#endregion */
/*
    End Navbar styling
*/
/*
    Begin Sidebar styling
*/
/*#region Sidebar */
#menu * {
  color: #004859 !important;
}

#menu {
  left: -180px;
  border-right: 1px solid #e4e5e7;
  z-index: 1000;
  background-color: #f7f9fa;
}

#menu.open {
  left: 0;
}

#side-menu li > a {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

#side-menu li > a > i {
  font-size: 18px;
  margin-right: 8px;
}

#side-menu li > a > span {
  flex-grow: 1;
}

@media (min-width: 768px) {
  #menu {
    left: 0;
  }
}
/*#endregion */
/*
    End Sidebar styling
*/

/*
    Begin Menu backdrop styling
*/

#MenuBackdrop {
  z-index: 999;
}

/*
    End Menu backdrop styling
*/

/*
    Begin Wrapper styling
*/
/*#region Wrapper */
#Wrapper {
  min-height: 100vh;
  background: #f1f3f6;
  padding-top: 62px;
  position: relative;
  display: flex;
  flex-direction: column;
}

#Wrapper > header .hpanel {
  margin: 0;
}

#Wrapper > header h2 {
  font-size: 18px;
}

#Wrapper > footer {
  background-color: #fff;
  padding: 10px 18px;
  border-top: 1px solid #e4e5e7;
  width: 100%;
}

main {
  display: block;
  flex-grow: 1;
}

@media (min-width: 768px) {
  #Wrapper {
    margin: 0 0 0 180px;
  }
}
/*#endregion */

/*
    End Wrapper styling
*/

/*#region dl */
dl {
  margin: 0;
}

.dl-horizontal > dt {
  float: left;
  width: 125px;
  clear: left;
  padding: 2px;
  text-align: left;
}

.dl-horizontal > dd {
  margin-left: 125px;
  padding: 2px;
}
/*#endregion */

textarea {
  resize: vertical;
}

.politespace-proxy {
  display: block;
}

.icheckbox.disabled ins {
  cursor: not-allowed !important;
}

.icheckbox.disabled + .checkbox-label {
  cursor: not-allowed !important;
}

.checkbox-label.hover {
  cursor: pointer;
}

.input-group.date-picker .input-group-addon {
  cursor: pointer;
}

.patientZoomNav {
  width: 100%;
  margin-top: 0px;
  margin-left: 0;
  margin-right: 0;
}

.patientZoomNav > li {
  /*max-width: 56px;*/
  position: relative;
  min-height: 1px;
  width: 16.66666667%;
  overflow: hidden;
  float: left;
}

.patientZoomNav > li > a {
  text-align: center;
  padding: 15px 5px;
}

.patientZoomNav > li:last-child > a {
  padding-right: 5px;
}

#addToCareTeamModal .modal-header {
  padding: 0;
  border-bottom: 0;
}

#addToCareTeamModal .modal-header h4 {
  margin: 40px 30px 20px 30px;
}

#addToCareTeamModal .nav-tabs li {
  width: 50%;
}

#addToCareTeamModal .nav-tabs li a {
  text-align: center;
  font-size: 16px;
}

#addToCareTeamModal form .form-group .btn {
  margin-right: 10px;
}

#Medications .section-header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

#Medications .section-header .h2 {
  flex-grow: 1;
  margin: 0;
}

#Medications .section-header > div {
  display: flex;
  align-items: center;
}

#Medications .section-header label {
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: normal;
}

#Medications .table-list li .text-danger {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}

#Medications .table-list li.inactive {
  display: none;
}

#noActive + li {
  border: none;
}

.table-list {
  list-style: none;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.table-list li:not(:first-of-type) {
  border-top: 1px solid #e5e5e5;
}

.table-list li .item-header {
  background-color: #f7f9fa;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.table-list li .item-body {
  padding: 5px 10px;
}

.table-list li .item-body > div {
  padding: 5px 0;
}

@media (min-width: 768px) {
  #preferences {
    margin-top: 20px;
  }

  #preferences h2 {
    margin-bottom: 15px;
  }

  #preferences .row {
    margin: 0;
  }

  #preferences form .row {
    border-top: 1px solid #e5e5e5;
    padding: 5px 0;
  }

  #preferences .row:nth-of-type(2n) {
    background-color: #f7f9fa;
  }

  #preferences .header {
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 600;
  }

  #preferences *[class^="col-"] {
    text-align: center;
  }

  #preferences *[class^="col-"].name {
    border: 0;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    font-size: 16px;
    margin: 0;
    text-align: left;
  }

  #preferences form *[class^="col-"].name {
    font-size: 14px;
  }

  #preferences form > .row:last-of-type {
    background: none;
    text-align: center;
    margin-top: 0;
    padding-top: 15px;
    border-top: 1px solid #e5e5e5;
  }
}

.user-list {
  background-color: #f1f3f6;
  border: 1px solid #e5e5e5;
  padding: 3px 8px;
}

.user-list .hpanel {
  margin: 5px 0;
}

.user-list .panel-body {
  padding: 8px 12px;
  border-top: 1px solid #e4e5e7;
}

.user-list .panel-body:hover {
  border-width: 2px;
  padding: 7px 11px;
  background-color: #f9fbfd;
  cursor: pointer;
}

.user-list .panel-body.selected {
  border-color: #3498db;
  border-width: 2px;
  padding: 7px 11px;
}

.user-list .panel-body .name {
  font-size: 15px;
  margin-bottom: 3px;
}

.user-list .panel-body .name span {
  display: inline-block;
}

.user-list .panel-body .row {
  font-size: 12px;
}

#userSearch .add-btn {
  float: right;
  margin-top: 10px;
  display: none;
}

#myPatients > div {
  position: relative;
  min-height: 300px;
}

#myPatients h2 {
  margin: 30px 5px 10px;
}

#patientSearchAndSort {
  padding: 5px;
  width: 100vw;
}

@media (max-width: 768px) {
  #patientSearchAndSort.affix-top,
  #patientSearchAndSort.affix {
    position: fixed;
    bottom: 20px;
    left: 0;
    z-index: 100;
  }
}

#patientSearchAndSort .well {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 5px;
}

#patientSearchAndSort .form-control {
  padding-top: 3px;
  padding-bottom: 3px;
  height: auto;
}

#patientSearchAndSort .form-control + .form-control {
  margin-top: 5px;
  margin-bottom: 5px;
}

#patientSearchAndSort .collapsed {
  display: none;
}

#patientSearchAndSort .expanded {
  width: 100%;
  height: auto;
  position: relative;
}

#patientSearchAndSort .animating {
  height: 0;
  width: 0;
  position: relative;
  overflow: hidden;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: height, width;
  transition-property: height, width;
}

#patientSearch {
  float: left;
}

#patientSearch .well {
  padding-top: 5px;
}

#patientSort {
  float: right;
}

#patientSort .well {
  display: flex;
}

#patientSort .well .btn {
  margin-left: 5px;
}

#searchBtn,
#sortBtn {
  border-radius: 10px;
  padding: 5px;
  font-size: 18px;
  width: 45px;
  height: 45px;
}

#searchBtn {
  float: left;
}

#sortBtn {
  float: right;
}

#myPatients .field.admit-date,
#myPatients .field.discharge-date {
  display: none;
}

.patient-list {
  padding: 10px 0 0;
  background-color: #f7f9fa;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  min-height: 350px;
}

.patient-list .patient {
  padding: 0 10px;
}

.patient-list .hpanel {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #e4e5e7;
  border-radius: 4px;
}

.patient-list .hpanel:hover {
  border: 2px solid #62cb31;
  cursor: pointer;
  /*Needed so panel contents aren't affected*/
  margin: -1px;
  margin-bottom: 9px;
}

.patient-list .hpanel a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 10;
}

.patient-list .hpanel .panel-heading {
  display: flex;
  position: relative;
  align-items: center;
  border: none;
}

.patient-list .hpanel .panel-heading object,
.patient-list .hpanel .panel-heading img {
  height: 50px;
}

.patient-list .hpanel .panel-heading > div {
  margin-left: 10px;
  margin-right: Calc(15% + 10px);
  flex-grow: 1;
}

.patient-list .hpanel .panel-heading button {
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  width: 15%;
  background: inherit;
  border: 0;
  font-size: 18px;
  outline: none;
  z-index: 11;
}

.patient-list .hpanel .name {
  font-size: 18px;
}

.patient-list .hpanel .name span {
  display: inline-block;
}

.patient-list .panel-body {
  border: none;
}

.patient-list .panel-body .field {
  margin: 5px 0;
}

.patient-list .panel-body .field:first-of-type {
  margin-top: 0;
}

.patient-list .panel-body .field:last-of-type {
  margin-bottom: 0;
}

.patient-list .panel-body .field span {
  display: inline-block;
}

.patient-list .panel-body .field strong {
  display: inline-block;
  margin-right: 5px;
}

.patient-list .ajax-loading {
  font-size: 16px;
  width: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  #myPatients h2 {
    margin-bottom: 20px;
  }

  #patientSearchAndSort h4 {
    margin-top: 0;
  }

  #patientSearchAndSort {
    width: 250px;
  }

  #patientSearchAndSort.affix-top {
    position: absolute;
    top: 0;
  }

  #patientSearchAndSort.affix {
    top: 75px;
  }

  #patientSearchAndSort .collapsed {
    display: block;
  }

  #patientSearch {
    float: none;
    margin-bottom: 20px;
  }

  #patientSort {
    float: none;
  }

  .patient-list {
    margin: 0 0 0 255px;
  }
}

@media (min-width: 992px) {
  .patient-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px 0;
    margin: 0 0 0 255px;
  }

  .patient-list .hpanel {
    margin-bottom: 20px;
  }

  .patient-list .hpanel:hover {
    margin-bottom: 19px;
  }

  .patient-list .hpanel.compact .panel-heading {
    border-bottom: none;
  }

  .patient-list .hpanel .panel-heading button {
    display: none;
  }

  .patient-list .hpanel .collapse {
    display: block;
  }
}

#Patient .panel-heading {
  display: flex;
  align-items: center;
}

#Patient .panel-heading img,
#Patient .panel-heading object {
  height: 80px;
}

#Patient .panel-heading > div {
  flex-grow: 1;
  margin-left: 15px;
}

#Patient .panel-heading .h3 span {
  display: inline-block;
}

#Patient .panel-body {
  padding: 13px 15px;
}

#Patient .panel-body div + div {
  margin-top: 4px;
}

#manageCareTeams > .row {
  position: relative;
}

#manageCareTeams h2 {
  margin-bottom: 20px;
}

#manageCareTeams .divider {
  background-color: #e5e5e5;
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  left: 50%;
}

#careTeamSelect .dropdown {
  display: inline-block;
  margin-left: 5px;
}

#careTeamMembers .member-list {
  display: none;
}

#patientEvents .sub-timeline {
  -webkit-transition: opacity 0.15s ease-in, height 0.35s ease-in-out;
  transition: opacity 0.15s ease-in, height 0.35s ease-in-out;
  overflow: hidden;
  margin-bottom: 2em;
}

#patientEvents .stay-summary {
  margin-left: 60px;
  margin-bottom: 0;
}

#patientEvents .stay-summary .panel-heading {
  padding-top: 0;
  line-height: 1;
}

#patientEvents .stay-summary .panel-heading a {
  float: right;
}

#patientEvents .stay-summary .panel-body > div {
  display: flex;
  align-items: center;
}

#patientEvents .stay-summary .panel-body > div:not(:last-of-type) {
  margin-bottom: 10px;
}

#patientEvents .stay-summary .panel-body .summary-icon {
  border: 1px solid #e5e5e7;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 8px;
  display: flex;
  align-items: center;
  text-align: center;
}

#patientEvents .stay-summary .panel-body .summary-icon i {
  width: 100%;
}

#patientEvents .stay-details {
  display: none;
  margin-top: -2em;
  margin-bottom: -2em;
}

#patientEvents .stay-details a {
  float: right;
  font-weight: 600;
  position: relative;
  top: 1.8em;
}

#careTeamList {
  /*background-color: #f7f9fa;*/
  border: 1px solid #e4e5e7;
  padding: 10px;
  border-radius: 2px;
}

#careTeamList .member:not(:last-of-type) {
  padding-bottom: 15px;
}

#careTeamList .name {
  font-size: 16px;
  margin-bottom: 2px;
}

#Print #AddToCareTeamBtn {
  display: none;
}

#Print #careTeamList .hpanel:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  #careTeamList .email {
    float: left;
    min-width: 50%;
    padding-right: 10px;
  }
}

table.hover-rows > tbody > tr:hover,
table.hover-rows > tbody > tr.hover,
table.hover-rows > tbody > tr:hover + tr.tablesorter-childRow,
table.clickable-rows > tbody > tr.open,
table.clickable-rows > tbody > tr.open + tr.tablesorter-childRow {
  background-color: #f1f1f1;
}

table.clickable-rows > tbody > tr:not(.tablesorter-childRow) {
  cursor: pointer;
}

table.clickable-rows tbody > tr.open > td {
  padding-bottom: 3px;
}

table.clickable-rows tbody > tr.tablesorter-childRow {
  display: none;
}

table.clickable-rows tbody > tr.tablesorter-childRow > td {
  border: 0;
  padding-top: 0;
  padding-bottom: 5px;
}

table.clickable-rows tbody > tr.tablesorter-childRow > td > a {
  margin-right: 15px;
}

.tablesorter > tbody > tr > td,
.tablesorter > tfoot > tr > td {
  padding-right: 20px;
  padding-left: 10px;
}

.tablesorter .tablesorter-header {
  cursor: pointer;
}

.tablesorter .tablesorter-header .fa {
  display: none;
}

.tablesorter .tablesorter-header.tablesorter-headerUnSorted .fa.fa-sort {
  display: inline-block;
}

.tablesorter .tablesorter-header.tablesorter-headerAsc .fa.fa-sort-up {
  display: inline-block;
}

.tablesorter .tablesorter-header.tablesorter-headerDesc .fa.fa-sort-down {
  display: inline-block;
}

.tablesorter .tablesorter-header-inner {
  display: flex;
  align-items: flex-end;
}

.tablesorter .tablesorter-header-inner .header-text {
  flex-grow: 1;
  padding-right: 5px;
}

.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  color: #b6b6b6;
}

@media (max-width: 992px) {
  #tableSearchFilter,
  #tableHeaderContent {
    flex-wrap: wrap;
  }
}

#InviteUserBtn {
  width: 100%;
}

.validation-msg {
  list-style: disc;
}

#Events .ajax-loading {
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
}

#menu .has-arrow {
  position: relative;
}

#menu .has-arrow::after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: currentColor;
  border-color: initial;
  right: 1em;
  -webkit-transform: rotate(-45deg) translate(0, -50%);
  transform: rotate(-45deg) translate(0, -50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  top: 50%;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#menu .active > .has-arrow::after,
#menu .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

.template-row {
  display: none;
}

.table .sort:hover {
  cursor: pointer;
  background-color: #f1f3f6;
}

.table .sort .glyphicon-sort-by-attributes,
.table .sort .glyphicon-sort-by-attributes-alt {
  display: none;
}

.table .sort.asc .glyphicon-sort-by-attributes {
  display: inline;
}

.table .sort.desc .glyphicon-sort-by-attributes-alt {
  display: inline;
}

.table .sort.asc .glyphicon-sort,
.table .sort.desc .glyphicon-sort {
  display: none;
}

.table th.sort > i {
  float: right;
  margin-top: 2px;
  margin-right: 2px;
}

.table .sort .glyphicon-sort {
  opacity: 0.5;
}

#ClinicalAlerts .panel-heading {
  padding-top: 0;
}

#ClinicalAlerts form .form-group {
  width: 150px;
  margin: 0 5px;
}

#ClinicalAlerts .patient {
  width: 170px;
  text-transform: capitalize;
}

#ClinicalAlerts .keywords {
  width: 120px;
  text-transform: capitalize;
}

#ClinicalAlerts .date {
  width: 150px;
}

#ClinicalAlerts .author {
  width: 150px;
}

.hpanel .nav.nav-tabs > li > a {
  border-radius: 0;
}

.updates-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search-row {
  display: flex;
  flex-direction: row;
}
.search-row span.input-group-addon {
  width: 30px;
}

#custom-tool-tip {
  z-index: 200;
  max-width: 50%;
  color: #1565c0;
  font-size: 13px;
  margin-top: 5px;
  position: absolute;
  margin-left: 100px;
  border-radius: 4px;
  padding: 10px 10px 0px 10px;
  background-color: #90caf9;
}

.tool-tip-arrow {
  width: 0;
  height: 0;
  margin-left: 60px;
  margin-bottom: 5px;
  border-top: 5px solid #90caf9;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.more-info-wrapper {
  width: 95px;
  z-index: 100;
  display: flex;
  cursor: pointer;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  align-items: center;
  flex-direction: row;
}
.more-info-wrapper i {
  color: white;
  font-size: 11px;
  padding: 5px 9px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: rgb(69, 122, 178);
}
.more-info-wrapper:hover i {
  background-color: rgb(47, 82, 121);
}
.more-info-wrapper p {
  margin: 0px;
  padding: 0px;
  font-size: 14;
  color: rgb(69, 122, 178);
}
.more-info-wrapper:hover p {
  color: rgb(47, 82, 121);
  text-decoration: underline;
}

.patient-content .panel-body .therapy-container {
  margin-top: 10px;
}
.patient-content .panel-body .p-xs {
  padding: 10px 0 !important;
}
.patient-content .panel-body .search-row {
  padding-right: 10px !important;
}

.header-nav ul li p {
  margin-top: 4px;
}
.header-nav ul li a {
  font-size: 12px;
}

.powerbi-container {
  height: 600px;
  width: 100%;
  border: 0;
  padding: 0px;
}

