/*Color Codes:
    Dark blue:
        CMYK: 95, 62, 48, 33
        RGB: 0, 72, 89
        HEX: #004859
    Middle blue:
        CMYK: 81, 20, 42, 1
        RGB: 1, 153, 153
        HEX: #019999
    Light blue:
        CMYK: 68, 0, 33, 0
        RGB: 0, 204, 194
        HEX: #00ccc2
*/
/*
    Begin navbar styling
*/
/* Override react-dates styles */
.SingleDatePickerInput {
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
}
.SingleDatePickerInput .SingleDatePickerInput_calendarIcon {
  padding: 8px;
  vertical-align: initial;
  margin: 0;
}
.SingleDatePickerInput .DateInput_input {
  padding: 8px 0;
  font-family: inherit !important;
  font-size: inherit !important;
}
/*#region Navbar */
#header {
  background-color: #f7f9fa;
  min-width: 320px;
}

#header * {
  color: #004859;
}

button.header-link {
  margin-right: 0;
  border-width: 0;
  border-right-width: 1px;
}

#header .navbar-brand {
  height: 55px;
  padding-left: 8px;
}

#header .navbar-brand img {
  height: 100%;
}

#header .navbar {
  margin-bottom: 0;
  border: none;
}

#header .navbar-collapse {
  border-top: 1px solid #e4e5e7;
}

.color-line {
  background-color: #004859;
  background-image: -moz-linear-gradient(left, #004859, #019999);
  background-image: -o-linear-gradient(left, #004859, #019999);
  background-image: -webkit-linear-gradient(left, #004859, #019999);
  background-image: -webkit-linear-gradient(left, #004859, #019999);
  background-image: linear-gradient(to right, #004859, #004859 33%, #019999 33%, #019999 67%, #00ccc2 67%, #00ccc2);
  background-size: 100% 6px;
  background-position: 0 100%;
  background-repeat: repeat-x;
}

#logo.light-version {
  padding: 0;
  border-right: 1px solid;
  border-color: inherit;
}

#logo a {
  padding: 0 15px;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

#logo img {
  width: 100%;
}

.navbar-nav > li > a {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.navbar-nav > li > a > i {
  font-size: 25px;
  margin-right: 10px;
}

.navbar-nav li.dropdown {
  height: auto;
}

.navbar-nav .dropdown-menu {
  width: 100%;
  padding-left: 35px;
  font-size: 15px;
}

@media (min-width: 768px) {
  .color-line {
    background-size: 50% 6px;
  }

  #header .navbar-header {
    border-right: 1px solid #e4e5e7;
    width: 180px;
  }

  #header .navbar-brand {
    padding-left: 15px;
  }

  #header .navbar-collapse {
    border: none;
    background-color: #fff;
    margin-left: 180px;
  }

  .navbar-nav .dropdown-menu {
    padding-left: 27px;
  }
}
/*#endregion */
/*
    End Navbar styling
*/
/*
    Begin Sidebar styling
*/
/*#region Sidebar */
#menu * {
  color: #004859 !important;
}

#menu {
  left: -180px;
  border-right: 1px solid #e4e5e7;
  z-index: 1000;
  background-color: #f7f9fa;
}

#menu.open {
  left: 0;
}

#side-menu li > a {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

#side-menu li > a > i {
  font-size: 18px;
  margin-right: 8px;
}

#side-menu li > a > span {
  flex-grow: 1;
}

@media (min-width: 768px) {
  #menu {
    left: 0;
  }
}
/*#endregion */
/*
    End Sidebar styling
*/

/*
    Begin Menu backdrop styling
*/

#MenuBackdrop {
  z-index: 999;
}

/*
    End Menu backdrop styling
*/

/*
    Begin Wrapper styling
*/
/*#region Wrapper */
#Wrapper {
  min-height: 100vh;
  background: #f1f3f6;
  padding-top: 62px;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

#Wrapper > header .hpanel {
  margin: 0;
}

#Wrapper > header h2 {
  font-size: 18px;
}

#Wrapper > footer {
  background-color: #fff;
  padding: 10px 18px;
  border-top: 1px solid #e4e5e7;
  width: 100%;
}

main {
  display: block;
  flex-grow: 1;
}

@media (min-width: 768px) {
  #Wrapper {
    margin: 0 0 0 180px;
  }
}
/*#endregion */

/*
    End Wrapper styling
*/

/*#region dl */
dl {
  margin: 0;
}

.dl-horizontal > dt {
  float: left;
  width: 125px;
  clear: left;
  padding: 2px;
  text-align: left;
}

.dl-horizontal > dd {
  margin-left: 125px;
  padding: 2px;
}
/*#endregion */

textarea {
  resize: vertical;
}

.politespace-proxy {
  display: block;
}

.icheckbox.disabled ins {
  cursor: not-allowed !important;
}

.icheckbox.disabled + .checkbox-label {
  cursor: not-allowed !important;
}

.checkbox-label.hover {
  cursor: pointer;
}

.input-group.date-picker .input-group-addon {
  cursor: pointer;
}

.patientZoomNav {
  width: 100%;
  margin-top: 0px;
  margin-left: 0;
  margin-right: 0;
}

.patientZoomNav > li {
  /*max-width: 56px;*/
  position: relative;
  min-height: 1px;
  width: 16.66666667%;
  overflow: hidden;
  float: left;
}

.patientZoomNav > li > a {
  text-align: center;
  padding: 15px 5px;
}

.patientZoomNav > li:last-child > a {
  padding-right: 5px;
}

#addToCareTeamModal .modal-header {
  padding: 0;
  border-bottom: 0;
}

#addToCareTeamModal .modal-header h4 {
  margin: 40px 30px 20px 30px;
}

#addToCareTeamModal .nav-tabs li {
  width: 50%;
}

#addToCareTeamModal .nav-tabs li a {
  text-align: center;
  font-size: 16px;
}

#addToCareTeamModal form .form-group .btn {
  margin-right: 10px;
}

#Medications .section-header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

#Medications .section-header .h2 {
  flex-grow: 1;
  margin: 0;
}

#Medications .section-header > div {
  display: flex;
  align-items: center;
}

#Medications .section-header label {
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: normal;
}

#Medications .table-list li .text-danger {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}

#Medications .table-list li.inactive {
  display: none;
}

#noActive + li {
  border: none;
}

.table-list {
  list-style: none;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.table-list li:not(:first-of-type) {
  border-top: 1px solid #e5e5e5;
}

.table-list li .item-header {
  background-color: #f7f9fa;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
}

.table-list li .item-body {
  padding: 5px 10px;
}

.table-list li .item-body > div {
  padding: 5px 0;
}

@media (min-width: 768px) {
  #preferences {
    margin-top: 20px;
  }

  #preferences h2 {
    margin-bottom: 15px;
  }

  #preferences .row {
    margin: 0;
  }

  #preferences form .row {
    border-top: 1px solid #e5e5e5;
    padding: 5px 0;
  }

  #preferences .row:nth-of-type(2n) {
    background-color: #f7f9fa;
  }

  #preferences .header {
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 600;
  }

  #preferences *[class^="col-"] {
    text-align: center;
  }

  #preferences *[class^="col-"].name {
    border: 0;
    background: initial;
    font-size: 16px;
    margin: 0;
    text-align: left;
  }

  #preferences form *[class^="col-"].name {
    font-size: 14px;
  }

  #preferences form > .row:last-of-type {
    background: none;
    text-align: center;
    margin-top: 0;
    padding-top: 15px;
    border-top: 1px solid #e5e5e5;
  }
}

.user-list {
  background-color: #f1f3f6;
  border: 1px solid #e5e5e5;
  padding: 3px 8px;
}

.user-list .hpanel {
  margin: 5px 0;
}

.user-list .panel-body {
  padding: 8px 12px;
  border-top: 1px solid #e4e5e7;
}

.user-list .panel-body:hover {
  border-width: 2px;
  padding: 7px 11px;
  background-color: #f9fbfd;
  cursor: pointer;
}

.user-list .panel-body.selected {
  border-color: #3498db;
  border-width: 2px;
  padding: 7px 11px;
}

.user-list .panel-body .name {
  font-size: 15px;
  margin-bottom: 3px;
}

.user-list .panel-body .name span {
  display: inline-block;
}

.user-list .panel-body .row {
  font-size: 12px;
}

#userSearch .add-btn {
  float: right;
  margin-top: 10px;
  display: none;
}

#myPatients > div {
  position: relative;
  min-height: 300px;
}

#myPatients h2 {
  margin: 30px 5px 10px;
}

#patientSearchAndSort {
  padding: 5px;
  width: 100vw;
}

@media (max-width: 768px) {
  #patientSearchAndSort.affix-top,
  #patientSearchAndSort.affix {
    position: fixed;
    bottom: 20px;
    left: 0;
    z-index: 100;
  }
}

#patientSearchAndSort .well {
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 5px;
}

#patientSearchAndSort .form-control {
  padding-top: 3px;
  padding-bottom: 3px;
  height: auto;
}

#patientSearchAndSort .form-control + .form-control {
  margin-top: 5px;
  margin-bottom: 5px;
}

#patientSearchAndSort .collapsed {
  display: none;
}

#patientSearchAndSort .expanded {
  width: 100%;
  height: auto;
  position: relative;
}

#patientSearchAndSort .animating {
  height: 0;
  width: 0;
  position: relative;
  overflow: hidden;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -moz-transition-property: height, width;
  -o-transition-property: height, width;
  -webkit-transition-property: height, width;
  transition-property: height, width;
}

#patientSearch {
  float: left;
}

#patientSearch .well {
  padding-top: 5px;
}

#patientSort {
  float: right;
}

#patientSort .well {
  display: flex;
}

#patientSort .well .btn {
  margin-left: 5px;
}

#searchBtn,
#sortBtn {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 5px;
  font-size: 18px;
  width: 45px;
  height: 45px;
}

#searchBtn {
  float: left;
}

#sortBtn {
  float: right;
}

#myPatients .field.admit-date,
#myPatients .field.discharge-date {
  display: none;
}

.patient-list {
  padding: 10px 0 0;
  background-color: #f7f9fa;
  border: 1px solid #e5e5e5;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  min-height: 350px;
}

.patient-list .patient {
  padding: 0 10px;
}

.patient-list .hpanel {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #e4e5e7;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.patient-list .hpanel:hover {
  border: 2px solid #62cb31;
  cursor: pointer;
  /*Needed so panel contents aren't affected*/
  margin: -1px;
  margin-bottom: 9px;
}

.patient-list .hpanel a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 10;
}

.patient-list .hpanel .panel-heading {
  display: flex;
  position: relative;
  align-items: center;
  border: none;
}

.patient-list .hpanel .panel-heading object,
.patient-list .hpanel .panel-heading img {
  height: 50px;
}

.patient-list .hpanel .panel-heading > div {
  margin-left: 10px;
  margin-right: Calc(15% + 10px);
  flex-grow: 1;
}

.patient-list .hpanel .panel-heading button {
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  width: 15%;
  background: inherit;
  border: 0;
  font-size: 18px;
  outline: none;
  z-index: 11;
}

.patient-list .hpanel .name {
  font-size: 18px;
}

.patient-list .hpanel .name span {
  display: inline-block;
}

.patient-list .panel-body {
  border: none;
}

.patient-list .panel-body .field {
  margin: 5px 0;
}

.patient-list .panel-body .field:first-of-type {
  margin-top: 0;
}

.patient-list .panel-body .field:last-of-type {
  margin-bottom: 0;
}

.patient-list .panel-body .field span {
  display: inline-block;
}

.patient-list .panel-body .field strong {
  display: inline-block;
  margin-right: 5px;
}

.patient-list .ajax-loading {
  font-size: 16px;
  width: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  #myPatients h2 {
    margin-bottom: 20px;
  }

  #patientSearchAndSort h4 {
    margin-top: 0;
  }

  #patientSearchAndSort {
    width: 250px;
  }

  #patientSearchAndSort.affix-top {
    position: absolute;
    top: 0;
  }

  #patientSearchAndSort.affix {
    top: 75px;
  }

  #patientSearchAndSort .collapsed {
    display: block;
  }

  #patientSearch {
    float: none;
    margin-bottom: 20px;
  }

  #patientSort {
    float: none;
  }

  .patient-list {
    margin: 0 0 0 255px;
  }
}

@media (min-width: 992px) {
  .patient-list {
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 10px 0;
    margin: 0 0 0 255px;
  }

  .patient-list .hpanel {
    margin-bottom: 20px;
  }

  .patient-list .hpanel:hover {
    margin-bottom: 19px;
  }

  .patient-list .hpanel.compact .panel-heading {
    border-bottom: none;
  }

  .patient-list .hpanel .panel-heading button {
    display: none;
  }

  .patient-list .hpanel .collapse {
    display: block;
  }
}

#Patient .panel-heading {
  display: flex;
  align-items: center;
}

#Patient .panel-heading img,
#Patient .panel-heading object {
  height: 80px;
}

#Patient .panel-heading > div {
  flex-grow: 1;
  margin-left: 15px;
}

#Patient .panel-heading .h3 span {
  display: inline-block;
}

#Patient .panel-body {
  padding: 13px 15px;
}

#Patient .panel-body div + div {
  margin-top: 4px;
}

#manageCareTeams > .row {
  position: relative;
}

#manageCareTeams h2 {
  margin-bottom: 20px;
}

#manageCareTeams .divider {
  background-color: #e5e5e5;
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  left: 50%;
}

#careTeamSelect .dropdown {
  display: inline-block;
  margin-left: 5px;
}

#careTeamMembers .member-list {
  display: none;
}

#patientEvents .sub-timeline {
  -moz-transition: opacity 0.15s ease-in, height 0.35s ease-in-out;
  -o-transition: opacity 0.15s ease-in, height 0.35s ease-in-out;
  -webkit-transition: opacity 0.15s ease-in, height 0.35s ease-in-out;
  transition: opacity 0.15s ease-in, height 0.35s ease-in-out;
  overflow: hidden;
  margin-bottom: 2em;
}

#patientEvents .stay-summary {
  margin-left: 60px;
  margin-bottom: 0;
}

#patientEvents .stay-summary .panel-heading {
  padding-top: 0;
  line-height: 1;
}

#patientEvents .stay-summary .panel-heading a {
  float: right;
}

#patientEvents .stay-summary .panel-body > div {
  display: flex;
  align-items: center;
}

#patientEvents .stay-summary .panel-body > div:not(:last-of-type) {
  margin-bottom: 10px;
}

#patientEvents .stay-summary .panel-body .summary-icon {
  border: 1px solid #e5e5e7;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 8px;
  display: flex;
  align-items: center;
  text-align: center;
}

#patientEvents .stay-summary .panel-body .summary-icon i {
  width: 100%;
}

#patientEvents .stay-details {
  display: none;
  margin-top: -2em;
  margin-bottom: -2em;
}

#patientEvents .stay-details a {
  float: right;
  font-weight: 600;
  position: relative;
  top: 1.8em;
}

#careTeamList {
  /*background-color: #f7f9fa;*/
  border: 1px solid #e4e5e7;
  padding: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

#careTeamList .member:not(:last-of-type) {
  padding-bottom: 15px;
}

#careTeamList .name {
  font-size: 16px;
  margin-bottom: 2px;
}

#Print #AddToCareTeamBtn {
  display: none;
}

#Print #careTeamList .hpanel:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  #careTeamList .email {
    float: left;
    min-width: 50%;
    padding-right: 10px;
  }
}

table.hover-rows > tbody > tr:hover,
table.hover-rows > tbody > tr.hover,
table.hover-rows > tbody > tr:hover + tr.tablesorter-childRow,
table.clickable-rows > tbody > tr.open,
table.clickable-rows > tbody > tr.open + tr.tablesorter-childRow {
  background-color: #f1f1f1;
}

table.clickable-rows > tbody > tr:not(.tablesorter-childRow) {
  cursor: pointer;
}

table.clickable-rows tbody > tr.open > td {
  padding-bottom: 3px;
}

table.clickable-rows tbody > tr.tablesorter-childRow {
  display: none;
}

table.clickable-rows tbody > tr.tablesorter-childRow > td {
  border: 0;
  padding-top: 0;
  padding-bottom: 5px;
}

table.clickable-rows tbody > tr.tablesorter-childRow > td > a {
  margin-right: 15px;
}

.tablesorter > tbody > tr > td,
.tablesorter > tfoot > tr > td {
  padding-right: 20px;
  padding-left: 10px;
}

.tablesorter .tablesorter-header {
  cursor: pointer;
}

.tablesorter .tablesorter-header .fa {
  display: none;
}

.tablesorter .tablesorter-header.tablesorter-headerUnSorted .fa.fa-sort {
  display: inline-block;
}

.tablesorter .tablesorter-header.tablesorter-headerAsc .fa.fa-sort-up {
  display: inline-block;
}

.tablesorter .tablesorter-header.tablesorter-headerDesc .fa.fa-sort-down {
  display: inline-block;
}

.tablesorter .tablesorter-header-inner {
  display: flex;
  align-items: flex-end;
}

.tablesorter .tablesorter-header-inner .header-text {
  flex-grow: 1;
  padding-right: 5px;
}

.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  color: #b6b6b6;
}

@media (max-width: 992px) {
  #tableSearchFilter,
  #tableHeaderContent {
    flex-wrap: wrap;
  }
}

#InviteUserBtn {
  width: 100%;
}

.validation-msg {
  list-style: disc;
}

#Events .ajax-loading {
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
}

#menu .has-arrow {
  position: relative;
}

#menu .has-arrow::after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: initial;
  right: 1em;
  -webkit-transform: rotate(-45deg) translate(0, -50%);
  -ms-transform: rotate(-45deg) translate(0, -50%);
  -o-transform: rotate(-45deg) translate(0, -50%);
  transform: rotate(-45deg) translate(0, -50%);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  top: 50%;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

#menu .active > .has-arrow::after,
#menu .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

.template-row {
  display: none;
}

.table .sort:hover {
  cursor: pointer;
  background-color: #f1f3f6;
}

.table .sort .glyphicon-sort-by-attributes,
.table .sort .glyphicon-sort-by-attributes-alt {
  display: none;
}

.table .sort.asc .glyphicon-sort-by-attributes {
  display: inline;
}

.table .sort.desc .glyphicon-sort-by-attributes-alt {
  display: inline;
}

.table .sort.asc .glyphicon-sort,
.table .sort.desc .glyphicon-sort {
  display: none;
}

.table th.sort > i {
  float: right;
  margin-top: 2px;
  margin-right: 2px;
}

.table .sort .glyphicon-sort {
  opacity: 0.5;
}

#ClinicalAlerts .panel-heading {
  padding-top: 0;
}

#ClinicalAlerts form .form-group {
  width: 150px;
  margin: 0 5px;
}

#ClinicalAlerts .patient {
  width: 170px;
  text-transform: capitalize;
}

#ClinicalAlerts .keywords {
  width: 120px;
  text-transform: capitalize;
}

#ClinicalAlerts .date {
  width: 150px;
}

#ClinicalAlerts .author {
  width: 150px;
}

.hpanel .nav.nav-tabs > li > a {
  border-radius: 0;
}

.updates-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search-row {
  display: flex;
  flex-direction: row;
}
.search-row span.input-group-addon {
  width: 30px;
}

#custom-tool-tip {
  z-index: 200;
  max-width: 50%;
  color: #1565c0;
  font-size: 13px;
  margin-top: 5px;
  position: absolute;
  margin-left: 100px;
  border-radius: 4px;
  padding: 10px 10px 0px 10px;
  background-color: #90caf9;
}

.tool-tip-arrow {
  width: 0;
  height: 0;
  margin-left: 60px;
  margin-bottom: 5px;
  border-top: 5px solid #90caf9;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.more-info-wrapper {
  width: 95px;
  z-index: 100;
  display: flex;
  cursor: pointer;
  transition: 0.1s;
  align-items: center;
  flex-direction: row;
}
.more-info-wrapper i {
  color: white;
  font-size: 11px;
  padding: 5px 9px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: rgb(69, 122, 178);
}
.more-info-wrapper:hover i {
  background-color: rgb(47, 82, 121);
}
.more-info-wrapper p {
  margin: 0px;
  padding: 0px;
  font-size: 14;
  color: rgb(69, 122, 178);
}
.more-info-wrapper:hover p {
  color: rgb(47, 82, 121);
  text-decoration: underline;
}

.patient-content .panel-body .therapy-container {
  margin-top: 10px;
}
.patient-content .panel-body .p-xs {
  padding: 10px 0 !important;
}
.patient-content .panel-body .search-row {
  padding-right: 10px !important;
}

.header-nav ul li p {
  margin-top: 4px;
}
.header-nav ul li a {
  font-size: 12px;
}

.powerbi-container {
  height: 600px;
  width: 100%;
  border: 0;
  padding: 0px;
}
